<script>
    import User from './user.svelte'

    export let commentUserImage;
    export let commentUserName;
    export let commentDescription;
    export let commentPublished;

</script>

<div class='comment'>
  <User userImage={commentUserImage} userName={commentUserName} userPublished={commentPublished} />

  <p class='text-comment'>{commentDescription}</p>
</div>