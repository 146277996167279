<script>
  import { onMount, afterUpdate } from 'svelte'
  import Post from '../components/post.svelte'

  let response = []
  let device = MQBE.current_state()

  onMount(async () => {
    const url = '/get_posts?per_paginate=3'
    const res = await fetch(url)
    response = await res.json()
  })

  afterUpdate(() => {
    initSwiper()
  })

  function initSwiper() {
    let $block = jQuery('.b-posts-slider')
    let $swiperContainer = jQuery('.js-posts-slider')

    $swiperContainer.swiper({
      slidesPerView: 'auto',
      autoHeight: true,
      prevButton: $block.find('.swiper-button-prev')[0],
      nextButton: $block.find('.swiper-button-next')[0],
      breakpoints: {
        768: {
          slidesPerView: 1,
        },
      },
    })
  }
</script>

<div class="posts group">
  {#if response.result !== undefined}
    {#each response.result.posts as post}
      <Post
        postId={post.id}
        postUserImage={post.avatar}
        postUserName={post.user_name}
        postDescription={post.description}
        postImage={post.image}
        postVideo={post.video}
        postVideoType={post.video_type}
        postYoutubeVideo={post.youtube_url}
        postCommentsCount={post.post_comments_count}
        postLikesCount={post.post_likes_count}
        postLikes={post.post_likes}
        postPublished={post.published_at}
        postComments={post.post_comments}
        showComments={false}
        {device}
      />
    {/each}
  {:else}
    <p>{window.i18nIbeLive.loading}</p>
  {/if}
</div>
