/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_svelte' %> (and
// <%= stylesheet_pack_tag 'hello_svelte' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Svelte!</div> at the bottom of the page.

import IberostarLive from '../container/iberostarLive.svelte';
import PostsSlider from '../container/PostsSlider.svelte';

document.addEventListener('DOMContentLoaded', () => {

  let iberostarLiveNode = document.getElementsByClassName("b-list-post_iberostar-live");
  let postsSliderNode = document.getElementsByClassName("b-posts-slider");

  // saves i18n strings in global variable
  let svelteAppContainer = document.getElementsByClassName("svelte-app");
  if (svelteAppContainer.length > 0) {
    window.i18nIbeLive = JSON.parse(svelteAppContainer[0].dataset.posts);
  }

  if (iberostarLiveNode.length) {

    const app = new IberostarLive({ target: iberostarLiveNode[0] });

    window.app = app;
  }

  if (postsSliderNode.length) {
    const app = new PostsSlider({ target: postsSliderNode[0] });

    window.app = app;
  }
});
