<script>
  import { onMount } from 'svelte';

  export let postYoutubeVideo;

  let youtubeCode;
  let posterHeight = 0;

   onMount(async () => {
    youtubeCode = getYoutubeVideoId(postYoutubeVideo);

  });

  function getYoutubeVideoId(url) {
    let youtubeId = url.split('=')[1];

    if (youtubeId !== undefined && youtubeId.indexOf('&') != -1) {
      youtubeId = youtubeId.substring(0, youtubeId.indexOf('&'));
    }
    if (youtubeId === undefined) {
      youtubeId = url.split('.be/')[1];
    }
    return youtubeId;
  }

  function calcVideoHeight() {
    posterHeight = document.getElementsByClassName("poster-youtube-img")[0].offsetHeight;
  }

</script>

{#if posterHeight === 0}
  <div on:click={calcVideoHeight} class='poster-youtube'>
    <img class='poster-youtube-img' src="//img.youtube.com/vi/{youtubeCode}/maxresdefault.jpg" alt="Video youtube poster">
  </div>
{:else}
  <iframe title="youtube video" src="https://www.youtube.com/embed/{youtubeCode}?autoplay=1&mute=1&playsinline=1" height="{posterHeight}px"></iframe>
{/if}
