<script>
  import { onMount, afterUpdate } from 'svelte'
  import Post from '../components/post.svelte'

  let postsList = []
  let postsListActualPage = []
  let nextPage = null
  let prevPage = null
  let numberPage = 1
  const postPerPage = 10
  let device = MQBE.current_state()

  onMount(async () => {
    let lang = document.getElementsByTagName('html')[0].lang
    lang = lang === 'es' ? '' : '/' + lang
    let url = `${lang}/get_posts`
    getPosts(url)
  })

  async function getPosts(url) {
    const res = await fetch(url)
    const response = await res.json()
    postsList.push(response)
    updatePage()
  }

  function loadPrev() {
    numberPage--
    updatePage()
    scrollFirstPost()
  }

  function loadNext() {
    numberPage++
    if (postsList.length < numberPage) {
      const url = `/get_posts?page=${numberPage}`
      getPosts(url)
    } else {
      updatePage()
    }
    scrollFirstPost()
  }

  function scrollFirstPost() {
    document.getElementsByClassName('posts')[0].scrollIntoView(true)
    window.scrollBy(0, -100)
  }

  function updatePage() {
    postsListActualPage = []
    postsList[numberPage - 1].result.posts.forEach(updateActualPosts)
    nextPage = postsList[numberPage - 1].next_page
    prevPage = postsList[numberPage - 1].prev_page
  }

  function updateActualPosts(item, index) {
    postsListActualPage = [...postsListActualPage, item]
  }

  function translateToPageString(position) {
    return window.i18nIbeLive.post[`${position}_page`]
  }
</script>

<div class="posts single">
  {#if postsListActualPage.length > 0}
    {#each postsListActualPage as post, i}
      <Post
        postId={post.id}
        postUserImage={post.avatar}
        postUserName={post.user_name}
        postDescription={post.description}
        postImage={post.image}
        postVideo={post.video}
        postVideoType={post.video_type}
        postYoutubeVideo={post.youtube_url}
        postCommentsCount={post.post_comments_count}
        postLikesCount={post.post_likes_count}
        postLikes={post.post_likes}
        postPublished={post.published_at}
        postComments={post.post_comments}
        showComments={true}
        {device}
      />
    {/each}
  {:else}
    <p>{window.i18nIbeLive.loading}</p>
  {/if}

  <div class="navigation">
    {#if prevPage !== 'null' && prevPage !== null}
      <button on:click={loadPrev} class="prev-page link" type="button">{@html translateToPageString('prev')}</button>
    {/if}

    {#if nextPage !== 'null' && prevPage !== null}
      <button on:click={loadNext} class="next-page link" type="button">{@html translateToPageString('next')}</button>
    {/if}
  </div>
</div>
