<script>
  import { getContext } from 'svelte';
  import { fly } from 'svelte/transition';

  import LikePopupContent from './likePopupContent.svelte';

  export let postLikesCount;
  export let likes;

  const { open } = getContext('simple-modal');

  let opening = false;
  let opened = false;
  let closing = false;
  let closed = false;

  const showPopup = () => {
    open(LikePopupContent, { message: likes });
  };

  let name;
  let status = 0;

  const onCancel = (text) => {
    name = '';
    status = -1;
  }

  const onOkay = (text) => {
    name = text;
    status = 1;
  }
</script>

<button on:click={showPopup} class='likes-number'>{postLikesCount} {window.i18nIbeLive.post.like} </button>
