<script>
  import Comment from './comment.svelte'
  import User from './user.svelte'
  import Like from './like.svelte'
  import YoutubeVideo from './youtubeVideo.svelte'
  import { onMount } from 'svelte'

  // Properties
  export let postId
  export let postUserImage
  export let postUserName
  export let postDescription
  export let postImage
  export let postVideo
  export let postVideoType
  export let postYoutubeVideo
  export let postCommentsCount
  export let postLikesCount
  export let postLikes
  export let postPublished
  export let postComments
  export let showComments
  export let device

  // Variables
  const commentsToLoad = 10
  let numberComments = 1
  let textComment = ''

  // Reactive declaration
  $: postListComments = postComments.reverse()
  $: disabledPublicComment = !(textComment.length > 0)
  $: videoType = postVideoType !== null ? postVideoType.replace('application', 'video').replace('quicktime', 'mp4') : ''

  function showMoreComments() {
    numberComments += commentsToLoad
  }

  async function newComment() {
    const url = `/posts/${postId}/post_comments`
    const data = new FormData()
    data.append('post_comment', JSON.stringify({ description: textComment, post_id: postId }))

    const res = await fetch(url, { method: 'POST', body: data })
    const response = await res.json()

    postComments = [...postComments, response.post]
    textComment = ''
    postCommentsCount++
  }

  onMount(async () => {
    let maxHeight = 0

    document.querySelectorAll('.posts.group .description').forEach(item => {
      if (item.offsetHeight > maxHeight) {
        maxHeight = item.offsetHeight
      }
    })

    document.querySelectorAll('.posts.group .description').forEach(item => {
      item.style.minHeight = maxHeight + 'px'
    })
  })
</script>

<article class="post">
  <div class="content">
    <User userImage={postUserImage} userName={postUserName} userPublished={postPublished} />

    <p class="description">{postDescription}</p>

    {#if postImage.desktop_url !== 'null' && postImage.desktop_url !== null && postImage.desktop_url !== ''}
      <div class="image">
        {#if device === 'mobile'}
          <a href={postImage.mobile_url} data-fancybox class="image-lightbox">Photo</a>
          <img src={postImage.mobile_url} alt="" />
        {:else}
          <a href={postImage.original_url} data-fancybox class="image-lightbox">Photo</a>
          <img src={postImage.desktop_url} alt="" />
        {/if}
      </div>
    {/if}

    {#if postVideo !== 'null' && postVideo !== null && postVideo !== ''}
      <video controls class="js-uploaded-video">
        <source src="{postVideo}#t=0.3" type={videoType} />
        Your browser does not support the video tag.
      </video>
    {/if}

    {#if postYoutubeVideo !== 'null' && postYoutubeVideo !== null && postYoutubeVideo !== ''}
      <YoutubeVideo {postYoutubeVideo} />
    {/if}

    <!-- {#if postIsHlsVideo}
      <div class='hls-videos'>
        <video class='hls-video' data-url="{postHlsUrl}" data-video-id="{postHlsVideoID}" id="video_hls_{postHlsVideoID}" controls></video>
      </div>
    {/if} -->

    <div class="options">
      <Like likes={postLikes} {postId} {postLikesCount} />
      <p class="comments-number">{postCommentsCount} {window.i18nIbeLive.post.comments}</p>
    </div>
  </div>

  {#if showComments}
    <div class="comments">
      <div class="new-comment">
        <textarea bind:value={textComment} maxlength="256" class="text-comment" rows="1" cols="50" placeholder={window.i18nIbeLive.post.comment_placeholder}></textarea>
        <button on:click={newComment} class="e-btn link utag-comment-send" type="button" disabled={disabledPublicComment}
          ><span class="send">{window.i18nIbeLive.post.publish}</span></button
        >
      </div>

      {#each postListComments as comment, i}
        {#if i < numberComments}
          <Comment commentUserImage={comment.avatar} commentUserName={comment.user_name} commentDescription={comment.description} commentPublished={comment.created_at} />
        {/if}
      {:else}
        <p class="no-comments">{window.i18nIbeLive.post.no_comments}</p>
      {/each}
    </div>
    {#if postComments.length > numberComments}
      <button on:click={showMoreComments} class="show-comments link" type="button"> {window.i18nIbeLive.post.see_more_comments}</button>
    {/if}
  {/if}
</article>
