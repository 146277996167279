<script>
    import { onMount } from 'svelte';

    import Modal from './modal.svelte';
    import LikePopupButton from './likePopupButton.svelte';

    // Properties
    export let likes;
    export let postId;
    export let postLikesCount;

    // Variables
    let idLike = -1;
    let userId = getCookie("employee_id");

    onMount(() => {
      // Check if logged user has active like in the post
      likes.forEach(element => {
        if(element.employee_id === userId) {
          idLike = element.id;
        }
      });
    });

    function toggleLike() {
      if (idLike === -1) {
        like();
      } else {
        dislike();
      }
    }

    async function like() {
      const url = `/posts/${postId}/post_likes`;
      const res = await fetch(url, {method: 'POST'});
      const response = await res.json();

      likes = [...likes, response.post_like];
      idLike = response.post_like.id;
      postLikesCount++;
    }

    async function dislike() {
      const url = `/posts/${postId}/post_likes/${idLike}`;
      await fetch(url, {method: 'DELETE'});

      idLike = -1;
      postLikesCount--;
    }



</script>

<button on:click={toggleLike} class='option-like link {idLike !== -1 ? 'active' : ''}' type='button'><span class='like'>{window.i18nIbeLive.post.like}</span></button>

<Modal>
	<LikePopupButton postLikesCount={postLikesCount} likes={likes} />
</Modal>
