<script>
    export let userImage;
    export let userName;
    export let userPublished;

    let imageClass = userImage !== '' && userImage !== null ? '' : 'no-image-user'
</script>

<div class='user'>
  <div class="default-image {imageClass}"></div>
  <img class="user-image {imageClass}" src='{userImage}' alt='Avatar'>
  <p class='user-name'>{userName}</p>
  {#if userPublished !== undefined}
    <p class='user-published'>{userPublished}</p>
  {/if}
</div>
