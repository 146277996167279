<script>
	import User from './user.svelte'

  export let message;

  function copyWithTotalLikes(total_likes) {
    return window.i18nIbeLive.post.popup_like.replace('%', total_likes);
  }
</script>

<div class="total-likes">
  <p>{copyWithTotalLikes(message.length)}</p>
</div>

<div class="people-likes">
	{#each message as like}
		<User userImage={like.avatar} userName={like.user_name} />
	{/each}
</div>
